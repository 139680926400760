<template>
  <div class="container">
    <div v-if="exception">
      Exception occurred.
    </div>
    <div class="content-box" v-else-if="devlog">
      <h1>{{ devlog.fields.title }}</h1>
      <h3>{{ devlog.sys.createdAt | date }}</h3>

      <rich-text-renderer :document="devlog.fields.text"/>

      <div class="screenshots" v-if="devlog.fields.screenshots">
        <template v-for="(screenshot, index) in devlog.fields.screenshots">
          <div class="screenshots__image" :key="screenshot.fileName">
            <img
              :src="screenshot.fields.file.url+'?w=300'"
              :alt="screenshot.fields.title"
              @click="lightboxOpen = !lightboxOpen, lightboxImageIndex = (index + 1)"
            />
          </div>
        </template>
      </div>
    </div>

    <FsLightbox
      :toggler="lightboxOpen"
      :sources="images"
      :slide="lightboxImageIndex"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import FsLightbox from 'fslightbox-vue'

export default {
  name: 'NewsItem',
  components: {
    RichTextRenderer,
    FsLightbox
  },
  metaInfo () {
    return {
      title: `News - ${this.devlog ? this.devlog.fields.title : 'Item'}`,
      link: [
        { rel: 'canonical', href: `https://guildsandblades.com/news/${this.$route.params.id}` }
      ]
    }
  },
  data () {
    return {
      lightboxOpen: false,
      lightboxImageIndex: 0
    }
  },
  computed: {
    ...mapState('devlog', ['devlog', 'loading', 'exception']),
    images () {
      if (!this.devlog || !this.devlog.fields.screenshots) {
        return []
      }

      return this.devlog.fields.screenshots.map(image => image.fields.file.url + '?fm=jpg&fl=progressive')
    }
  },
  mounted () {
    this.fetchDevlogItem(this.$route.params.id)
  },
  methods: {
    ...mapActions('devlog', [
      'fetchDevlogItem'
    ])
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

.screenshots {
  margin-top: $spacing2;

  @media(min-width: $breakpointDesktopLg) {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
  }

  &__image {
    padding: 3px;
    margin-top: $spacing1;

    border: 1px solid transparentize($colorLink, 0.5);
    width: fit-content;

    @media(min-width: $breakpointDesktopLg) {
      display: grid;
      align-items: center;

      padding: 0;
      margin: 0;
    }

    img {
      filter: saturate(0);

      &:hover {
        filter: saturate(1);
        transition-property: filter;
        transition-duration: 0.5s;
        cursor: pointer;
      }
    }
  }
}
</style>
